import PaginationText from './PaginationText'
import BurgerButton from './BurgerButton'
import TextWithImage from './TextWithImage'
import Video from './Video'
import NumberOverview from './NumberOverview'
import Title from './Title'
import Table from './Table'
import Gallery from './Gallery'
import FullImage from './FullImage'
import Quote from './Quote'
import Link from './Link'
import Text from './Text'
import Menu from './Menu'
import Footer from './Footer'
import NavBar from './NavBar'
import TopHero from './TopHero'
import Logo from './Logo'
import Button from './Button'

export {
  PaginationText,
  BurgerButton,
  TextWithImage,
  Video,
  NumberOverview,
  Title,
  Table,
  Gallery,
  FullImage,
  Quote,
  Link,
  Text,
  Menu,
  Footer,
  NavBar,
  TopHero,
  Logo,
  Button
}
