import React, { Component } from 'react'
import Cmp from './Footer'
import { StaticQuery, graphql } from 'gatsby'

class Connected extends Component {
  render () {
    return (
      <StaticQuery
        query={graphql`
          query {
            allPrismicOptions {
              edges {
                node {
                  data {
                    facebook {
                      url
                    }
                    twitter {
                      url
                    }
                    linkedin {
                      url
                    }
                    youtube {
                      url
                    }
                    vimeo {
                      url
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => <Cmp data={data} {...this.props} />}
      />
    )
  }
}

export default Connected
