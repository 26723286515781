import React from 'react'
import styles from './Quote.module.scss'
import PropTypes from 'prop-types'
import className from 'classnames'
import { get } from 'lodash'

const Quote = items => {
  const text = get(items, 'primary.tilvitnun.text', '')

  return (
    <div className={className(styles.container)}>
      <blockquote>
        {text}
      </blockquote>
    </div>
  )
}

Quote.propTypes = {
  html: PropTypes.node
}

Quote.defaultProps = {
  html: ''
}

export default Quote
