import React from 'react'
import styles from './Gallery.module.scss'
import { get } from 'lodash'
import Image from 'gatsby-image'
import { hyphenate } from '../../utils/hypher.js'

const Gallery = data => {
  const images = get(data, 'items', [])
  const galleryTitle = get(data, 'primary.gallery_title.text', '')

  return (
    <div className={styles.container}>
      <h2 className='hdln--1 text-center pb-2 pt-2'>{hyphenate(galleryTitle)}</h2>

      <div className={styles.galleryContainer}>
        {images.map((x, i) => {
          const fluidImage = get(x, 'image.localFile.childImageSharp.fluid')
          const description = get(x, 'image_description.text', '')
          const title = get(x, 'image_title.text', '')

          return (
            <div
              className={styles.imageContainer}
              key={i}>
              {fluidImage &&
                <Image
                  className={styles.image}
                  fluid={fluidImage} />
              }
              <div className={styles.imageContent}>
                {title &&
                  <div className={styles.imageTitle}>
                    {hyphenate(title)}
                  </div>
                }
                {description &&
                  <div className={styles.imageDescription}>
                    {description}
                  </div>
                }
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Gallery
