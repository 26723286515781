import React from 'react'
import className from 'classnames'
import styles from './NavBar.module.scss'
import Logo from '../Logo'
import BurgerButton from '../BurgerButton'

const NavBar = () => {
  const containerClasses = className(styles.navBarContainer)

  return (
    <div className={containerClasses}>
      <div className={styles.navBar}>
        <Logo />
        <BurgerButton />
      </div>
    </div>
  )
}

export default NavBar
