import React, { Component, Fragment } from 'react'
import styles from './Menu.module.scss'
import className from 'classnames'
import { ConsumerWrapper } from '../../Context'
import { get } from 'lodash'
import { Link } from '../'
import { hyphenate } from '../../utils/hypher.js'

class Menu extends Component {
  constructor () {
    super()

    this.state = {
      showSubs: false,
      currentMenuItem: null
    }

    this.timer = null
    this.anchorsTimer = null
    this.onResize = this.onResize.bind(this)
    this.onTopLevelClick = this.onTopLevelClick.bind(this)
    this.toggleMenuTo = this.toggleMenuTo.bind(this)
  }

  componentDidMount () {
    window.addEventListener('resize', this.onResize)
    this.onResize()

    const {
      consumerContext
    } = this.props

    consumerContext.set({
      toggleMenuTo: (menuLabel, e) => {
        this.toggleMenuTo(menuLabel, e)
      }
    })
  }

  componentDidUpdate () {
    clearTimeout(this.timer)

    const {
      consumerContext: {
        showMenu
      }
    } = this.props

    const {
      showSubs,
      currentMenuItem
    } = this.state

    if (showMenu) {
      document.body.style.overflowY = 'hidden'
      this.onResize()
    } else {
      this.timer = setTimeout(() => {
        document.body.style.overflowY = 'auto'

        if (showSubs || currentMenuItem) {
          this.setState({
            showSubs: false,
            currentMenuItem: null
          })
        }
      }, 400)
    }
  }

  onTopLevelClick (item, e) {
    e.preventDefault()
    this.setState({
      showSubs: true,
      currentMenuItem: item
    })
  }

  toggleMenuTo(menuLabel, e) {
    const {
      data,
      consumerContext: {
        toggleMenu
      }
    } = this.props

    const menuContainerArr = get(data, 'allPrismicMenu.edges', [])

    const menuArr = menuContainerArr.map((menu, i) => {
      return get(menu, 'node.data.nav', [])
    })

    let item = null

    if (menuArr.length > 0) {
      menuArr.forEach((x, i) => {
        item = x.find(k => {
          const text = get(k, 'primary.label.text', '')
          return text === menuLabel
        })
      })
    }

    if (item) {
      this.onTopLevelClick(item, e)

      this.setState({
        showSubs: true
      }, () => {
        toggleMenu()
      })
    }
  }

  onResize () {
    clearTimeout(this.anchorsTimer)

    const {
      showSubs
    } = this.state

    if (this.mainMenu) {
      if (this.subMenu) {
        this.subMenu.style.height = `${this.mainMenu.offsetHeight}px`
      }

      const list = Array.prototype.slice.call(this.mainMenu.childNodes)

      const length = list.length - 1
      const anchors = []

      list.forEach((item, i) => {
        const anchor = item.querySelector('a')
        const itemWidth = item.offsetWidth
        const anchorWidth = anchor.offsetWidth
        const width = itemWidth - anchorWidth

        anchor.style.transition = 'none'

        // Center if subs are closed, go a bit further than the edge if open...
        const x = !showSubs
          ? width / 2
          : width * 1.075

        anchor.style.transform = `translateX(${x}px)`

        // A small animation on hover when subs are open
        anchor.onmouseover = () => {
          if (showSubs) {
            anchor.style.transform = `translateX(${width}px)`
          }
        }

        anchor.onmouseout = () => {
          if (showSubs) {
            anchor.style.transform = `translateX(${x}px)`
          }
        }

        anchors.push(anchor)

        if (length === i) {
          this.anchorsTimer = setTimeout(() => {
            anchors.forEach(x => {
              x.style.transition = 'transform .3s ease'
            })
          }, 400)
        }
      })
    }
  }

  setYAuto () {
    document.body.style.overflowY = 'auto'
  }

  getMenuItemUrl (item) {
    let linkUrl = '/'

    if (item.document !== null) {
      const parentUrl = get(item, 'document[0].data.page_parent.uid', '')
      const currentItemUrl = get(item, 'document[0].uid', '')
      linkUrl = parentUrl ? `/${parentUrl}` : ''
      linkUrl += currentItemUrl ? `/${currentItemUrl}` : ''
    }

    if (item.document === null) {
      linkUrl = get(item, 'url', '')
    }

    return linkUrl
  }

  render () {
    const {
      showSubs,
      currentMenuItem
    } = this.state

    const {
      topHeroContent,
      consumerContext: {
        showMenu
      },
      data
    } = this.props

    const imageSrc = get(topHeroContent, 'background.localFile.childImageSharp.fluid.src', '')
    const menuContainerArr = get(data, 'allPrismicMenu.edges', [])
    const subMenuItems = get(currentMenuItem, 'items', [])
    const currentMenutitle = get(currentMenuItem, 'primary.label.text', '')

    return (
      <div
        className={className(styles.container, {
          [styles.containerVisible]: showMenu
        })}>
        {imageSrc &&
          <div
            className={styles.menuBackground}
            style={{
              backgroundImage: `url(${imageSrc})`
            }} />
        }
        {menuContainerArr.length > 0 && menuContainerArr.map((menu, i) => {
          const menuArr = get(menu, 'node.data.nav', [])

          return (
            <Fragment key={i}>
              <ul
                ref={el => { this.mainMenu = el }}
                className={className(styles.mainMenu, { [styles.mainMenuSubsOpen]: showSubs })}>
                {menuArr.length > 0 && menuArr.map((item, i) => {
                  const label = get(item, 'primary.label.text', null)
                  const isCurrentMenuItem = currentMenutitle === label
                  const itemClasses = className({
                    [styles.currentItem]: isCurrentMenuItem
                  })
                  const topLevelLink = get(item, 'primary.top_level_link', null)
                  const topLevelUrl = topLevelLink ? this.getMenuItemUrl(topLevelLink) : ''

                  const liItem = topLevelUrl
                    ? <li key={i}><Link to={topLevelUrl} onClick={this.setYAuto}>{hyphenate(label)}</Link></li>
                    : <li key={i}><a className={itemClasses} onClick={e => this.onTopLevelClick(item, e)}>{hyphenate(label)}</a></li>

                  return liItem
                })}
              </ul>
              <ul
                ref={el => { this.subMenu = el }}
                className={className(styles.subMenu, { [styles.subMenuHidden]: !showSubs })}>
                {subMenuItems.length > 0 && subMenuItems.map((item, i) => {
                  const label = get(item, 'sub_nav_link_label.text', null)
                  const link = this.getMenuItemUrl(item.sub_nav_link)

                  return label && <li key={i}><Link onClick={this.setYAuto} to={link}>{hyphenate(label)}</Link></li>
                })}
              </ul>
            </Fragment>
          )
        })}
      </div>
    )
  }
}

export default ConsumerWrapper(Menu, ['set', 'showMenu', 'toggleMenuTo', 'toggleMenu'])
