import React from 'react'
import styles from './NumberOverview.module.scss'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartLine } from '@fortawesome/pro-solid-svg-icons'
import Button from '../Button'
import { get } from 'lodash'
import { Link } from '../'
import { getDocumentUrl } from '../../misc/utilities'
import { hyphenate } from '../../utils/hypher.js'

const NumberOverview = ({ primary, items }) => {
  const containerClasses = classNames('container', styles.container)
  const innerContainerClasses = classNames('pt-2', 'pb-2', styles.innerContainer)
  const dataColContainer = classNames('row', 'justify-content-center')
  const dataColClasses = classNames('col-12 col-xl-4', styles.dataCol)

  const title = get(primary, 'big_title.text', '')
  const shortTitle = get(primary, 'short_title.text', '')
  const text = get(primary, 'text.html', '')
  const linkText = get(primary, 'buttontext.text')
  const buttonLink = get(primary, 'button_link', '')
  const linkUrl = getDocumentUrl(buttonLink)

  return (
    <div className={containerClasses}>
      <div className={innerContainerClasses}>
        <div className='content-section'>
          {shortTitle &&
            <h2 className={'hdln--mini hdln--mini--boxed'}>{shortTitle}</h2>
          }
          <h1>{hyphenate(title)}</h1>
          <div dangerouslySetInnerHTML={{ __html: text }} />
        </div>
        <div className={dataColContainer}>
          {items && items.map((item, i) => {
            const title = get(item, 'column_title.text', '')
            const number = get(item, 'number.text', '')

            return (
              <div key={i} className={dataColClasses}>
                <h2><span className={styles.icon} ><FontAwesomeIcon icon={faChartLine} /></span> {hyphenate(title)}</h2>
                <h3>{number}</h3>
              </div>
            )
          })}
        </div>
        {linkUrl &&
          <Link to={linkUrl}>
            <Button>{linkText}</Button>
          </Link>
        }
      </div>
    </div>
  )
}

export default NumberOverview
