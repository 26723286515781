import React from 'react'
import styles from './Text.module.scss'
import PropTypes from 'prop-types'
import className from 'classnames'
import { get } from 'lodash'
import { getDocumentUrl } from '../../misc/utilities'
import { Link, Button } from '../'
import { hyphenate } from '../../utils/hypher.js'

const Text = items => {
  const html = get(items, 'primary.text.html', '')
  const linkUrlObj = get(items, 'primary.link_url', '')
  const linkUrl = getDocumentUrl(linkUrlObj)
  const linkText = get(items, 'primary.link_name.text', '')

  return (
    <div className={className('content-section', styles.container)}>
      <div dangerouslySetInnerHTML={{ __html: hyphenate(html) }} />
      {linkUrl && linkText &&
        <div className='pt-2'>
          <Link to={linkUrl}>
            <Button>{linkText}</Button>
          </Link>
        </div>
      }
    </div>

  )
}

Text.propTypes = {
  html: PropTypes.node
}

Text.defaultProps = {
  html: ''
}

export default Text
