import React, { Component, Fragment } from 'react'
import Hero from '../../components/Hero'
import Text from '../../components/Text'
import MyGoogleMap from '../../components/MyGoogleMap'
import TopHero from '../../components/TopHero'
import className from 'classnames'
import Quote from '../../components/Quote'
import Footer from '../../components/Footer'
import Main from '../../layouts/Main'
import Gallery from '../../components/Gallery'
import FullImage from '../../components/FullImage'
import Table from '../../components/Table'
import Title from '../../components/Title'
import NumberOverview from '../../components/NumberOverview'
import TextWithImage from '../../components/TextWithImage'
import Video from '../../components/Video'
import PaginationText from '../../components/PaginationText'
import { get } from 'lodash'
import { ConsumerWrapper } from '../../Context'
import '../../styles/index.scss'

const sliceTypes = {
  'hero': Hero,
  'text': Text,
  'kort': MyGoogleMap,
  'svipmyndir': Gallery,
  'stor_mynd': FullImage,
  'tilvitnun': Quote,
  'tafla': Table,
  'titill': Title,
  'lykiltolur': NumberOverview,
  'myndband': Video,
  'textasv__i_me__mynd': TextWithImage
}

class Page extends Component {
  render () {
    const {
      data
    } = this.props

    const content = get(data, 'prismicPage.data.body', [])
    const primaryContent = get(data, 'prismicPage.data', {})

    const topHeroContent = {
      text1: get(primaryContent, 'top_title'),
      text2: get(primaryContent, 'bottom_title'),
      button_text: get(primaryContent, 'button_text'),
      link: get(primaryContent, 'link'),
      background: get(primaryContent, 'background'),
      addOverlay: get(primaryContent, 'darken_background_image')
    }

    const currentPageId = get(data, 'prismicPage.prismicId', 0)
    const { isHomepage } = this.props.pageContext

    return (
      <Main topHeroContent={topHeroContent}>
        <TopHero primary={topHeroContent} />
        <ContentWrapper>
          <DisplayContent content={content} />
          <PaginationText currentPageId={currentPageId} isHomepage={isHomepage} />
          <Footer />
        </ContentWrapper>
      </Main>
    )
  }
}

const ContentWrapperComponent = props => {
  const {
    consumerContext: {
      showMenu
    }
  } = props

  return (
    <div className={className('content-wrapper', { 'content-wrapper--hidden': false })}>
      {props.children}
    </div>
  )
}

const ContentWrapper = ConsumerWrapper(ContentWrapperComponent, ['showMenu'])

const DisplayContent = ({ content }) => {
  return content.map((x, i) => {
    const Cmp = sliceTypes[x.slice_type]

    return (
      <Fragment key={i}>
        {Cmp && <Cmp {...x} index={i} />}
      </Fragment>
    )
  })
}

export default Page
