import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import { ContextProviderComponent } from '../../Context'
import NavBar from '../../components/NavBar'
import Menu from '../../components/Menu'
import favicon from '../../images/favicon.ico'

export default ({ children, topHeroContent }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allPrismicPage {
            edges {
              node {
                data {
                  title {
                    html
                    text
                  }
                }
              }
            }
          }
        }
      `}
      render={
        data => (
          <Fragment>
            <Helmet>
              <meta name='viewport' content='width=device-width, initial-scale=1' />
              <title>Ársskýrsla 2018</title>
              <link rel='shortcut icon' href={favicon} type='image/x-icon' />
            </Helmet>
            <ContextProviderComponent>
              <NavBar />
              <Menu topHeroContent={topHeroContent} />
              {children}
            </ContextProviderComponent>
          </Fragment>
        )
      }
    />
  )
}
