import React, { Component } from 'react'
import styles from './BurgerButton.module.scss'
import classNames from 'classnames'
import { ConsumerWrapper } from '../../Context'

class BurgerButton extends Component {
  constructor () {
    super()

    this.height = 40
    this.textHeight = 11

    this.state = {
      isOpen: false,
      textHeight: this.textHeight,
      textHeightAlt: 0,
      height: this.height,
      heightAlt: 0
    }
  }

  componentDidMount () {
    window.addEventListener('scroll', this.onUpdate.bind(this))
    window.addEventListener('resize', this.onUpdate.bind(this))
    this.onUpdate()

    const {
      consumerContext
    } = this.props

    consumerContext.set({
      toggleMenu: () => {
        this.onToggle()
      }
    })
  }

  onUpdate () {
    const {
      consumerContext: {
        showMenu
      }
    } = this.props

    if (this.el) {
      const rect = this.el.getBoundingClientRect()

      const height = this.getNewHeight(rect, this.height)

      if (height !== this.state.height) {
        this.setState({
          height,
          heightAlt: Math.abs(height - this.height)
        })
      }
    }
    this.updateTextHeight()
  }

  updateTextHeight () {
    if (this.textEl) {
      const rect = this.textEl.getBoundingClientRect()

      const textHeight = this.getNewHeight(rect, this.textHeight)

      if (textHeight !== this.state.textHeight) {
        this.setState({
          textHeight,
          textHeightAlt: Math.abs(textHeight - this.textHeight)
        })
      }
    }
  }

  getNewHeight (refEl, defaultHeight) {
    const diff = (window.innerHeight - refEl.bottom) - window.scrollY
    const newHeight = defaultHeight + diff

    let height = 0

    if (newHeight <= 0) {
      height = 0
    } else if (newHeight >= defaultHeight) {
      height = defaultHeight
    } else if (newHeight < defaultHeight) {
      height = newHeight
    }

    return height
  }

  onToggle () {
    const { consumerContext } = this.props
    const { isOpen } = this.state

    this.setState({
      isOpen: !isOpen
    }, () => {
      consumerContext.set({
        showMenu: this.state.isOpen
      })
    })
  }

  render () {
    const { isOpen, height, heightAlt, textHeight, textHeightAlt } = this.state

    const whiteBurgerHeight = !isOpen ? height : this.height
    const whiteTextHeight = !isOpen ? textHeight : this.textHeight

    return (
      <div
        className={styles.burgerSection}
        ref={el => { this.el = el }} >
        <div
          className={styles.burgerTextOuterContainer}
          ref={el => { this.textEl = el }}>
          <div className={styles.burgerTextContainer} style={{height: whiteTextHeight}}>
            <div className={styles.burgerText} >
              Efnisyfirlit
            </div>
          </div>
          {!isOpen &&
            <div className={classNames(styles.burgerTextContainer, styles.burgerTextContainerBlue)} style={{height: textHeightAlt}}>
              <div className={styles.burgerTextBlue}>
                Efnisyfirlit
              </div>
            </div>
          }
        </div>
        <Burger
          isOpen={isOpen}
          height={whiteBurgerHeight}
          onToggle={this.onToggle.bind(this)} />
        {!isOpen &&
          <Burger
            isOpen={isOpen}
            blue
            height={heightAlt}
            onToggle={this.onToggle.bind(this)} />
        }
      </div>
    )
  }
}

const Burger = ({ onToggle, isOpen, blue, height }) => {
  const burgerContainerClasses = classNames(styles.burgerButtonContainer, {
    [styles.burgerButtonContainerBlue]: blue
  })

  const burgerClasses = classNames(styles.burgerButton, {
    [styles.burgerButtonOpen]: isOpen,
    [styles.burgerBlue]: blue
  })

  const burgerOuterContainer = classNames(styles.burgerOuterContainer, {
    [styles.burgerOuterContainerBlue]: blue
  })

  return (
    <div
      className={burgerOuterContainer}
      style={{ height }}>
      <div
        onClick={onToggle}
        className={burgerContainerClasses}>
        <div className={burgerClasses}>
          <span />
          <span />
          <span />
          <span />
        </div>
      </div>
    </div>
  )
}

export default ConsumerWrapper(BurgerButton, ['set', 'showMenu', 'toggleMenu'])
