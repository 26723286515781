import React, { Component } from 'react'
import texture from '../../images/particle-hvit-15x15.png'
import isNode from 'detect-node'

let KawaseBlurFilter,
  TimelineMax,
  SlowMo,
  Texture,
  Application,
  utils,
  Container,
  Sprite

if (!isNode) {
  TimelineMax = require('gsap/all').TimelineMax
  SlowMo = require('gsap/all').SlowMo
  KawaseBlurFilter = require('@pixi/filter-kawase-blur').KawaseBlurFilter
  Texture = require('pixi.js').Texture
  Application = require('pixi.js').Application
  utils = require('pixi.js').utils
  Container = require('pixi.js').Container
  Sprite = require('pixi.js').Sprite
}

class Particles extends Component {
  constructor () {
    super()

    this.onResize = this.onResize.bind(this)
    this.randomInt = this.randomInt.bind(this)
    this.plusMinus = this.plusMinus.bind(this)
    this.moveParticle1 = this.moveParticle1.bind(this)
    this.moveParticle2 = this.moveParticle2.bind(this)
    this.renderParticle = this.renderParticle.bind(this)
    this.setupContainer = this.setupContainer.bind(this)
    this.run = this.run.bind(this)
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.onResize)
  }

  componentDidMount () {
    window.addEventListener('resize', this.onResize)
    this.run()
  }

  run () {
    this.sprite = Texture.from(texture)

    utils.skipHello()

    this.width = this.cnt.parentElement.offsetWidth
    this.height = this.cnt.parentElement.offsetHeight

    this.particlesCanvas = new Application(
      this.width,
      this.height,
      {
        antialias: false,
        autoResize: true,
        resolution: 1,
        transparent: true,
        forceFXAA: true
      }
    )

    this.cnt.appendChild(this.particlesCanvas.view)

    var blur1 = new KawaseBlurFilter()
    blur1.blur = 1
    blur1.quality = 1

    var blur2 = new KawaseBlurFilter()
    blur2.blur = 4
    blur2.quality = 1

    var blur3 = new KawaseBlurFilter()
    blur3.blur = 8
    blur3.quality = 1

    this.cnt1 = new Container()
    this.setupContainer(this.cnt1, {
      alpha: 0.4,
      sprite: this.sprite,
      cntFilters: [blur1],
      particles: 25,
      particleMovement: 1,
      particleScale: 1,
      cntReversed: false
    })

    this.cnt2 = new Container()
    this.setupContainer(this.cnt2, {
      alpha: 0.2,
      sprite: this.sprite,
      cntFilters: [blur2],
      particles: 15,
      particleMovement: 2,
      particleScale: 10,
      cntReversed: false
    })

    this.cnt3 = new Container()
    this.setupContainer(this.cnt3, {
      alpha: 0.2,
      sprite: this.sprite,
      cntFilters: [blur3],
      particles: 10,
      particleMovement: 2,
      particleScale: 20,
      cntReversed: true
    })
  }

  onResize () {
    this.width = this.cnt.parentElement.offsetWidth
    this.height = this.cnt.parentElement.offsetHeight

    this.particlesCanvas.renderer.resize(this.width, this.height)
  }

  randomInt (min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min
  }

  plusMinus () {
    return Math.round(Math.random()) * 2 - 1
  }

  moveParticle1 (particle) {
    var animation = new TimelineMax()
    var alpha = this.randomInt(1, 10) / 10
    var currentX = this.randomInt(-300, this.width + 10)
    var currentY = this.randomInt(-300, this.height + 10)
    var delay = this.randomInt(1, 10) / 5
    var duration = this.randomInt(5, 15)

    animation
      .set(particle, {
        pixi: {
          alpha: 0,
          scale: 0,
          x: currentX,
          y: currentY
        }
      })
      .delay(delay)
      .to(particle, 2, {
        pixi: {
          alpha: alpha,
          scale: 1
        },
        ease: Cubic.easeOut
      })
      .to(particle, duration + 4, {
        pixi: {
          x: currentX + 200,
          y: currentY + 200 + this.randomInt(1, 5) * 50
        },
        ease: Linear.easeNone
      }, 0)
      .to(particle, 2, {
        pixi: {
          alpha: 0,
          scale: 0
        },
        ease: Cubic.easeIn,
        onComplete: () => {
          this.moveParticle1(particle)
        }
      }, '-=2')
  }

  renderParticle (cnt, param) {
    var particle = new Sprite(param.sprite)

    var movement = param.movement ? param.movement : 1
    var scale = param.scale ? param.scale : 1
    var size = this.randomInt(100, 300) / 30 * scale

    particle.alpha = param.alpha
    particle.scale.x = param.scale
    particle.scale.y = param.scale
    particle.pivot.x = size / 2
    particle.pivot.y = size / 2
    particle.position.x = this.randomInt(-300, this.width + 10)
    particle.position.y = this.randomInt(-300, this.width + 10)

    cnt.addChild(particle)

    if (movement === 1) {
      this.moveParticle1(particle)
    } else {
      this.moveParticle2(particle)
    }
  }

  moveParticle2 (particle) {
    var animation = new TimelineMax()
    var currentX = particle.position.x < 0 || particle.position.x > this.width ? this.randomInt(0, this.width) : particle.position.x
    var currentY = particle.position.y < 0 || particle.position.y > this.height ? this.randomInt(0, this.height) : particle.position.y
    var duration = this.randomInt(2, 5)

    animation
      .to(particle, duration, {
        pixi: {
          alpha: 1,
          x: currentX + this.randomInt(10, 50) * 5 * this.plusMinus(),
          y: currentY + this.randomInt(10, 50) * 5 * this.plusMinus()
        },
        ease: SlowMo.easeInOut,
        onComplete: () => {
          this.moveParticle2(particle)
        }
      })
  }

  setupContainer (cnt, param) {
    if (!cnt) return

    this.particlesCanvas.stage.addChild(cnt)

    cnt.alpha = param.alpha ? param.alpha : 1
    cnt.filters = param.cntFilters ? param.cntFilters : null
    cnt.scale.x = param.cntReversed ? -1 : 1
    cnt.scale.y = param.cntReversed ? -1 : 1

    param.particleScale = param.particleScale ? param.particleScale : 1

    for (var i = 0; i < param.particles; i++) {
      this.renderParticle(cnt, {
        movement: param.particleMovement,
        scale: param.particleScale,
        sprite: param.sprite,
        alpha: param.alpha
      })
    }
  }

  render () {
    return (<div
      {...this.props}
      ref={cnt => { this.cnt = cnt }} />
    )
  }
}

export default Particles
