import React, { Component } from 'react'
import styles from './Table.module.scss'
import { get } from 'lodash'
import classNames from 'classnames'

const rowClasses = {
  'Normal': styles.normal,
  'Bold': styles.bold,
  'Title': styles.title,
  'Big title': styles.bigTitle
}

class Table extends Component {
  rowsParser (rows) {
    return rows.map((row, i) => {
      return {
        value: row,
        tdClass: !isNaN(parseInt(row)) ? styles.isNumber : ''
      }
    })
  }

  render () {
    const rows = get(this.props, 'items', [])
    return (
      <div className={classNames('content-section', styles.container)}>
        {rows.length > 0 &&
          <table>
            <tbody>
              {rows.map((row, i) => {
                const type = get(row, 'row_tegund', 'Normal')
                const rowsArr = [
                  get(row, 'col_1.text', ''),
                  get(row, 'col_2.text', ''),
                  get(row, 'col_3.text', '')
                ]

                const trArr = this.rowsParser(rowsArr)
                const prevRow = rows[i - 1]
                const prevRowType = get(prevRow, 'row_tegund', '')
                const isBoldAbove = prevRowType === type && type === 'Bold'

                const rowClass = classNames(rowClasses[type], {
                  [styles.boldAbove]: isBoldAbove
                })

                return (
                  <tr key={i} className={rowClass}>
                    {trArr.map((row, x) => {
                      return (
                        <td key={x} className={row.tdClass}>{row.value}</td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
        }
      </div>
    )
  }
}

export default Table
