import React, { Component } from 'react'
import className from 'classnames'
import styles from './TopHero.module.scss'
import BackgroundImage from 'gatsby-background-image'
import PropTypes from 'prop-types'
import { Button, Link } from '../'
import { get } from 'lodash'
import { ConsumerWrapper } from '../../Context'
import { getDocumentUrl } from '../../misc/utilities'
import { Controller, Scene } from 'react-scrollmagic'
import { Tween } from 'react-gsap'
import { hyphenate } from '../../utils/hypher.js'
import Particles from './Particles'

class TopHero extends Component {
  constructor (props) {
    super(props)

    this.state = {
      doTween: true
    }
  }

  componentDidMount () {
    window.addEventListener('resize', this.updateTween.bind(this))
    this.updateTween()
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.updateTween.bind(this))
  }

  updateTween () {
    this.setState({
      doTween: window.innerWidth > 500
    })
  }

  render () {
    const {
      consumerContext: {
        showMenu
      },
      primary
    } = this.props

    const {
      text1,
      text2,
      button_text,
      link,
      background,
      addOverlay
    } = primary

    const {
      doTween
    } = this.state

    const imageData = get(background, 'localFile.childImageSharp.fluid')
    const buttonLink = get(link, 'url')
    const buttonText = get(button_text, 'text')
    const linkUrl = getDocumentUrl(link)

    const t2 = get(text2, 'text', '')
    const t1 = get(text1, 'text', '')

    const mainTitle = `${t1} ${t2}`.trim()
    const words = mainTitle.split(' ')

    let lastWord = ''

    if (words.length >= 2) {
      lastWord = words.pop()
    }

    const firstWords = words.join(' ')

    const content = (
      <div
        className={className(styles.container, { [styles.menuOpen]: showMenu })}>
        <BackgroundImage
          Tag='div'
          id='top-hero'
          fadeIn
          ref={el => { this.el = el }}
          className={styles.backgroundContainer}
          fluid={imageData}
          backgroundColor={`#040e18`}
        >
          {addOverlay === 'Já' &&
            <div className={styles.overlay} />
          }
          <Particles className={styles.particlesContainer} />
          <div className={styles.content}>
            <h1 className={styles.text}>
              <span className={styles.text1}>
                {hyphenate(firstWords)}
              </span>
              {lastWord &&
                <span className={className(styles.text2)}>
                  {hyphenate(lastWord)}
                </span>
              }
            </h1>
            {linkUrl && buttonText &&
              <div className='text-center pt-1'>
                <Link to={buttonLink}>
                  <Button white>
                    {buttonText}
                  </Button>
                </Link>
              </div>
            }
          </div>
        </BackgroundImage>
      </div>
    )

    if (doTween) {
      return (
        <Controller>
          <Scene
            triggerElement='.content-wrapper'
            triggerHook={1}
            duration={'100%'}
          >
            {(progress) => {
              return (
                <Tween
                  to={{
                    opacity: .66,
                    top: -100
                  }}
                  totalProgress={progress}
                  paused
                >
                  {content}
                </Tween>
              )
            }}
          </Scene>
        </Controller>
      )
    }

    return content
  }
}

const defaultPropType = PropTypes.exact({
  text: PropTypes.string,
  html: PropTypes.string
})

TopHero.propTypes = {
  text1: defaultPropType,
  text2: defaultPropType,
  button_text: defaultPropType
}

const defaultProp = (text = '', html = '') => {
  return {
    text,
    html
  }
}

TopHero.defaultProps = {
  text1: defaultProp(''),
  text2: defaultProp(''),
  button_text: defaultProp('Sjá nánar')
}

export default ConsumerWrapper(TopHero, ['set', 'showMenu'])
