import React from 'react'
import styles from './FullImage.module.scss'
import Image from 'gatsby-image'
import { get } from 'lodash'

const FullImage = data => {
  const fluidImage = get(data, 'primary.image.localFile.childImageSharp.fluid')
  const description = get(data, 'primary.description.text', '')

  return (
    <div className={styles.container}>
      {fluidImage &&
        <Image
          className={styles.image}
          fluid={fluidImage} />
      }
      <div className={styles.description}>
        {description}
      </div>
    </div>
  )
}

export default FullImage
