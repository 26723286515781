import React from 'react'
import { get } from 'lodash'

const Title = (props) => {
  const shortTitle = get(props, 'primary.short_title.text', '')
  const largeTitle = get(props, 'primary.large_title.text', '')

  return (
    <div className='content-section headline-section'>
      {shortTitle &&
        <h2 className='hdln--mini'>{shortTitle}</h2>
      }
      {largeTitle &&
        <h1>{largeTitle}</h1>
      }
    </div>
  )
}

export default Title
