const _ = require(`lodash`)

exports.getDocumentUrl = link => {
  let linkUrl
  const linkType = _.get(link, 'link_type', null)

  if (linkType === 'Web') {
    linkUrl = _.get(link, 'url', '')
  }

  if (linkType === 'Document') {
    const parentUid = _.get(link, 'document[0].data.page_parent.uid', '')
    const childUid = _.get(link, 'document[0].uid', '')

    linkUrl = '/'
    linkUrl += parentUid ? `${parentUid}/` : ''
    linkUrl += childUid ? `${childUid}/` : ''
  }

  return linkUrl
}
