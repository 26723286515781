import React from 'react'
import className from 'classnames'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons'

const defaultIcon = <FontAwesomeIcon icon={faArrowRight} />

const Button = ({ white, children, icon, invert, large, alignRight, alignLeft, fullWidthMobile, ...props }) => {
  const btnClasses = className('btn btn--primary', {
    'btn--white': white,
    'btn--invert': invert,
    'btn--large': large,
    'btn--align-right': alignRight,
    'btn--align-left': alignLeft,
    'btn--full-mobile': fullWidthMobile
  })

  return (
    <button className={btnClasses} {...props}>
      {icon && invert &&
        <span className='btn__icon'>
          {icon}
        </span>
      }
      {children}
      {icon && !invert &&
        <span className='btn__icon'>
          {icon}
        </span>
      }
    </button>
  )
}

Button.propTypes = {
  icon: PropTypes.node
}

Button.defaultProps = {
  icon: defaultIcon
}

export default Button
