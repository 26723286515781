import React from 'react'
import { Link as GatsbyLink } from 'gatsby'

const Link = ({ children, to, className, ...props }) => {
  // test to see if url is internal
  const internal = /^\/(?!\/)/.test(to)

  if (internal) {
    return (
      <GatsbyLink to={to} className={className} {...props}>
        {children}
      </GatsbyLink>
    )
  }
  return (
    <a
      href={to}
      target='_blank'
      rel='noopener noreferrer'
      className={className}
      {...props}
    >
      {children}
    </a>
  )
}

export default Link
