import React, { Component, Fragment } from 'react'
import styles from './PaginationText.module.scss'
import classNames from 'classnames'
import { Button } from '../../components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons'
import { get } from 'lodash'
import { Link } from '../'

class PaginationText extends Component {
  constructor (props) {
    super(props)
    this.state = {
      beforeLink: {},
      nextLink: {}
    }

    this.nav = get(props, 'data.allPrismicMenu.edges[0].node.data.nav', [])
    this.setMenuItems = this.setMenuItems.bind(this)
    this.setBeforeAfterLinks = this.setBeforeAfterLinks.bind(this)
  }

  componentDidMount () {
    this.setMenuItems()
  }

  setMenuItems () {
    const currentId = this.props.currentPageId
    const menuTree = this.nav.reduce((cur, item) => {
      const list = item.items.length > 0 && item.items.reduce((curx, x) => {
        const doc = get(x, 'sub_nav_link.document', [])
        if (doc && doc.length > 0 && doc[0].prismicId === currentId) {
          curx.push(x)
        }
        return curx
      }, [])

      if (list.length > 0 && item.items.length > 0) {
        cur = item.items
      }

      return cur
    }, [])

    this.setBeforeAfterLinks(menuTree)
  }

  setBeforeAfterLinks (menuTree) {
    const currentId = this.props.currentPageId
    const currentItemIndex = menuTree.findIndex(item => {
      const doc = get(item, 'sub_nav_link.document', [])
      return doc && doc.length > 0 && doc[0].prismicId === currentId
    })

    const beforeLinkObject = currentItemIndex > 0 && menuTree[currentItemIndex - 1]
    const beforeLink = beforeLinkObject && beforeLinkObject.sub_nav_link && this.getMenuItemUrl(beforeLinkObject.sub_nav_link)
    let beforeLinkObj

    if (beforeLinkObject) {
      beforeLinkObj = {
        link: beforeLink,
        name: beforeLinkObject.sub_nav_link_label.text
      }
    } else {
      beforeLinkObj = {
        link: '/',
        name: 'Forsíðu'
      }
    }

    this.setState({
      beforeLink: beforeLinkObj
    })

    const nextLinkObject = currentItemIndex >= 0 && currentItemIndex < menuTree.length - 1 && menuTree[currentItemIndex + 1]
    const nextLink = nextLinkObject && nextLinkObject.sub_nav_link && this.getMenuItemUrl(nextLinkObject.sub_nav_link)

    if (nextLink) {
      const nextLinkObj = {
        link: nextLink,
        name: nextLinkObject.sub_nav_link_label.text
      }

      this.setState({
        nextLink: nextLinkObj
      })
    }
  }

  getMenuItemUrl (item) {
    let linkUrl = '/'

    if (item && item.document !== null) {
      const parentUrl = get(item, 'document[0].data.page_parent.uid', '')
      const currentItemUrl = get(item, 'document[0].uid', '')
      linkUrl = parentUrl ? `/${parentUrl}` : ''
      linkUrl += currentItemUrl ? `/${currentItemUrl}` : ''
    }

    if (item && item.document === null) {
      linkUrl = get(item, 'url', '')
    }

    return linkUrl
  }

  render () {
    const { beforeLink, nextLink } = this.state
    const { isHomepage } = this.props
    const leftArrowIcon = <FontAwesomeIcon icon={faArrowLeft} />
    const leftColClasses = classNames('col-lg-3', 'col-sm-5', 'col-5', styles.col, styles.colLeft)
    const rightColClasses = classNames('col-lg-3', 'col-sm-5', 'col-5', styles.col, styles.colRight)

    return (
      <Fragment>
        {!isHomepage &&
        <div className='container mt-2'>
          <div className='row justify-content-between'>
            <div className={leftColClasses}>
              {beforeLink && beforeLink.link &&
                <div>
                  <p className={classNames(styles.upperText)}>Til baka í</p>
                  <Link to={beforeLink.link}>
                    <Button invert icon={leftArrowIcon} alignRight large fullWidthMobile>{beforeLink.name}</Button>
                  </Link>
                </div>
              }
            </div>
            <div className={rightColClasses}>
              {nextLink && nextLink.link &&
                <div>
                  <p className={classNames(styles.upperText)}>Næst</p>
                  <Link to={nextLink.link}>
                    <Button large alignLeft fullWidthMobile>{nextLink.name}</Button>
                  </Link>
                </div>
              }
            </div>
          </div>
        </div>
        }
      </Fragment>
    )
  }
}

export default PaginationText
