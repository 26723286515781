import Cmp from './Page.js'
import { graphql } from 'gatsby'

export const pageQuery = graphql`
  query pageConnected($id: String!) {
    prismicPage(prismicId: {eq: $id}) {
      prismicId
      data {
        title {
          html
          text
        }
        top_title {
          text
        }
        bottom_title {
          text
        }
        button_text {
          text
        }
        link {
          link_type
          url
          document {
            uid
            data {
              page_parent {
                uid
              }
            }
          }
        }
        darken_background_image
        background {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1400, quality: 92) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                originalImg
                originalName
                presentationWidth
                presentationHeight
              }
            }
          }
        }
        body {
          __typename
          ... on PrismicPageBodyTextasvIMeMynd {
            slice_type
            primary {
              texti {
                html
              }
              myndatexti {
                text
              }
              link_name {
                text
              }
              link_url {
                url
                document {
                  uid
                  data {
                    page_parent {
                      uid
                    }
                  }
                }
                link_type
              }
              mynd {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 300, quality: 92) {
                      base64
                      tracedSVG
                      aspectRatio
                      src
                      srcSet
                      srcWebp
                      srcSetWebp
                      sizes
                      originalImg
                      originalName
                      presentationWidth
                      presentationHeight
                    }
                  }
                }
              }
            }
          }          
          ... on PrismicPageBodyMyndband {
            slice_type
            primary {
              video {
                embed_url
              }
            }
          }
          ... on PrismicPageBodyLykiltolur {
            slice_type
            primary {
              big_title {
                text
              }
              short_title {
                text
              }
              text {
                html
              }
              buttontext {
                text
              }
              button_link {
                document {
                  uid
                  data {
                    page_parent {
                      uid
                    }
                  }                  
                }
              }
            }
            items {
              column_title {
                text
              }
              number {
                text
              }
            }
          }          
          ... on PrismicPageBodyTitill {
            slice_type
            primary {
              large_title {
                text
              }
              short_title {
                text
              }
            }
          }          
          ... on PrismicPageBodyTafla {
            slice_type
            items {
              row_tegund
              col_1 {
                text
              }
              col_2 {
                text
              }
              col_3 {
                text
              }
            }
          }
          ... on PrismicPageBodySvipmyndir {
            slice_type
            primary {
              gallery_title {
                html
                text
              }
            }
            items {
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1400, quality: 92) {
                      base64
                      aspectRatio
                      src
                      srcSet
                      srcWebp
                      srcSetWebp
                      sizes
                      originalImg
                      originalName
                      presentationWidth
                      presentationHeight
                    }
                  }
                }
              }
              image_title {
                html
                text
              }
              image_description {
                html
                text
              }
            }
          }
          ... on PrismicPageBodyKort {
            slice_type
            primary {
              category_display {
                id
              }
              titill {
                text
              }
              texti {
                html
              }
              kort {
                document {
                  data {
                    title {
                      text
                    }
                    body {
                      primary {
                        marker_title {
                          text
                        }
                        marker_text {
                          html
                        }
                        marker_icon
                        marker_link_name {
                          text
                        }
                        marker_link {
                          url
                        }
                        marker_location {
                          latitude
                          longitude
                        }
                        marker_type {
                          document {
                            id
                            prismicId
                            data {
                              taxonomy_term {
                                text
                              }
                              color
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicPageBodyStorMynd {
            slice_type
            primary {
              description {
                html
                text
              }
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1400, quality: 92) {
                      base64
                      aspectRatio
                      src
                      srcSet
                      srcWebp
                      srcSetWebp
                      sizes
                      originalImg
                      originalName
                      presentationWidth
                      presentationHeight
                    }
                  }
                }
              }
            }
          }
          ... on PrismicPageBodyTilvitnun {
            slice_type
            primary {
              tilvitnun {
                html
                text
              }
            }
          }
          ... on PrismicPageBodyText {
            slice_type
            primary {
              link_name {
                text
              }
              link_url {
                url
                document {
                  uid
                  data {
                    page_parent {
                      uid
                    }
                  }
                }
                link_type
              }              
              text {
                html
              }
            }
          }
          ... on PrismicPageBodyHero {
            slice_type
            primary {
              hero_title {
                html
                text
              }
              hero_short_title {
                html
                text
              }
              hero_text {
                html
                text
              }
              hero_button_text {
                html
                text
              }
              hero_link {
                link_type
                url
                document {
                  __typename
                  ... on PrismicPage {
                    uid
                    data {
                      page_parent {
                        uid
                      }
                    }
                  }
                  ... on PrismicTopLevelPage {
                    uid
                    data {
                      title {
                        html
                        text
                      }
                    }
                  }
                }
              }
              hero_image {
                alt
                localFile {
                  childImageSharp {
                    fluid(cropFocus: ATTENTION, quality: 92) {
                      base64
                      aspectRatio
                      src
                      srcSet
                      srcWebp
                      srcSetWebp
                      sizes
                      originalImg
                      originalName
                      presentationWidth
                      presentationHeight
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Cmp
