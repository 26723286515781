import React from 'react'
import { isEqual } from 'lodash'
import defaultContextValue from './defaultValues'

const { Provider, Consumer } = React.createContext(defaultContextValue)

class ContextProviderComponent extends React.Component {
  constructor () {
    super()

    this.setData = this.setData.bind(this)
    this.state = {
      ...defaultContextValue,
      set: this.setData
    }
  }

  setData (data) {
    this.setState(data)
  }

  render () {
    const { children, props } = this.props
    return <Provider value={this.state} {...props}>{children}</Provider>
  }
}

const ConsumerWrapper = (WrappedComponent, subs = [], options = {}) => {
  return class extends React.Component {
    render () {
      return (
        <Consumer>
          {value => {
            // Reduce context data based on subscriptions
            const contextData = subs.reduce((acc, sub) => {
              if (value[sub]) {
                acc[sub] = value[sub]
              }
              return acc
            }, {})
            return (
              <ConsumerSubscriptionHandler
                consumerContextListeners={options.listen || []}
                cmp={WrappedComponent}
                consumerContext={contextData}
                {...this.props}
              />
            )
          }}
        </Consumer>
      )
    }
  }
}

class ConsumerSubscriptionHandler extends React.Component {
  shouldComponentUpdate (nextProps) {
    const { consumerContextListeners = [] } = this.props

    const listeners = [
      'consumerContext',
      ...consumerContextListeners
    ]

    return listeners.reduce((acc, listener) => {
      if (acc) {
        return acc
      }
      return !isEqual(this.props[listener], nextProps[listener])
    }, false)
  }
  render () {
    const { cmp: Cmp, consumerContextOptions, ...props } = this.props
    return <Cmp {...props} />
  }
}

export { Consumer as default, ConsumerWrapper, ContextProviderComponent }
