import React, { Fragment, Component } from 'react'
import className from 'classnames'
import styles from './Logo.module.scss'
import { navigate } from '@reach/router';
import { ConsumerWrapper } from '../../Context'
import { Link } from '../'

class Logo extends Component {
  constructor (props) {
    super(props)

    // Width and height of the svg
    this.height = 32
    this.width = 188

    this.state = {
      height: this.height,
      width: this.width,
      heightAlt: 0
    }
  }

  componentDidMount () {
    window.addEventListener('scroll', this.onUpdate.bind(this))
    window.addEventListener('resize', this.onUpdate.bind(this))
    this.onUpdate()
  }

  onUpdate () {
    if (this.el) {
      const rect = this.el.getBoundingClientRect()

      const diff = (window.innerHeight - rect.bottom) - window.scrollY
      const newHeight = this.height + diff

      let height = 0

      if (newHeight <= 0) {
        height = 0
      } else if (newHeight >= this.height) {
        height = this.height
      } else if (newHeight < this.height) {
        height = newHeight
      }

      if (height !== this.state.height) {
        this.setState({
          height,
          heightAlt: Math.abs(height - 32)
        })
      }
    }
  }

  render () {
    const {
      consumerContext: {
        showMenu
      }
    } = this.props

    const {
      height,
      heightAlt
    } = this.state

    return (
      <Link onClick={() => {
        document.body.style.overflowY = 'auto'
        navigate('/')
      }}>
        <div
          className={styles.container}
          ref={el => { this.el = el }}>
          <span className='sr-only'>Heim</span>
          {showMenu &&
            <Svg
              height={this.height}
              white />
          }
          {!showMenu &&
            <Fragment>
              <Svg
                white={showMenu}
                height={heightAlt} />
              <Svg
                white
                height={height} />
            </Fragment>
          }
        </div>
      </Link>
    )
  }
}

const Svg = ({ white, height }) => {
  return (
    <div
      style={{ height }}
      className={className(styles.logo, { [styles.logoWhite]: white })}>
      <svg width='188' height='32' viewBox='0 0 188 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path fillRule='evenodd' clipRule='evenodd' d='M59.4259 3.08289C59.4259 3.28956 59.3224 3.51688 59.1986 3.68181L58.2698 4.94181C58.0829 5.18955 57.8771 5.33384 57.464 5.33384C56.9887 5.33384 56.4723 4.9003 56.4723 4.38418C56.4723 4.13622 56.576 3.92977 56.7205 3.76439L57.6917 2.50528C57.8771 2.25688 58.1044 2.11237 58.476 2.11237C59.013 2.11237 59.4259 2.48397 59.4259 3.08289ZM56.2661 7.60588C56.2661 6.92438 56.8233 6.36674 57.5048 6.36674C58.1863 6.36674 58.7448 6.92438 58.7448 7.60588V15.7837C58.7448 16.4652 58.1863 17.023 57.5048 17.023C56.8233 17.023 56.2661 16.4652 56.2661 15.7837V7.60588Z' fill='#006CA9' />
        <path fillRule='evenodd' clipRule='evenodd' d='M66.5297 17.1056C64.6497 17.1056 63.4534 16.6305 62.4613 15.9488C62.0069 15.6396 61.7998 15.2673 61.7998 14.9163C61.7998 14.3585 62.2549 13.8841 62.8747 13.8841C63.1217 13.8841 63.4116 13.966 63.7007 14.1731C64.485 14.7101 65.3112 15.0817 66.4886 15.0817C67.7067 15.0817 68.5118 14.6892 68.5118 13.9458C68.5118 13.1824 67.5213 12.9546 66.6951 12.7684C62.9983 11.9428 62.0486 11.0959 62.0486 9.31982C62.0486 7.39941 63.6798 6.28436 66.6742 6.28436C67.7899 6.28436 68.8426 6.53188 69.8131 7.00693C70.3703 7.2751 70.6182 7.62672 70.6182 8.10133C70.6182 8.59681 70.2266 9.03079 69.6268 9.03079C69.4206 9.03079 69.1733 8.98972 68.9251 8.86541C68.223 8.53509 67.4585 8.30822 66.6951 8.30822C65.2697 8.30822 64.5262 8.59681 64.5262 9.25788C64.5262 9.83638 65.0637 10.0013 66.7361 10.4147C69.5449 11.1166 70.9903 11.7359 70.9903 13.7598C70.9903 15.8043 69.2552 17.1056 66.5297 17.1056Z' fill='#006CA9' />
        <path fillRule='evenodd' clipRule='evenodd' d='M80.8 14.751C81.3781 14.751 81.8527 15.2256 81.8527 15.8043C81.8527 16.383 81.3781 16.8576 80.8 16.8576H75.0175C74.336 16.8576 73.7788 16.2998 73.7788 15.6183V7.60589C73.7788 6.92439 74.336 6.36676 75.0175 6.36676C75.6992 6.36676 76.2571 6.92439 76.2571 7.60589V14.751H80.8Z' fill='#006CA9' />
        <path fillRule='evenodd' clipRule='evenodd' d='M87.3049 12.7271H90.0921L88.7089 9.48545L87.3049 12.7271ZM93.8717 15.4118C93.9334 15.5564 93.9953 15.7635 93.9953 15.9282C93.9953 16.5478 93.5207 17.023 92.8392 17.023C92.3016 17.023 91.9923 16.734 91.7861 16.2998L90.9392 14.6058H86.4782L85.6318 16.2998C85.4255 16.734 85.115 17.023 84.5786 17.023C83.8969 17.023 83.4219 16.5478 83.4219 15.9282C83.4219 15.7635 83.4836 15.5564 83.5462 15.4118L87.6765 7.02784C87.8829 6.61428 88.1717 6.36676 88.7089 6.36676C89.2459 6.36676 89.5141 6.61428 89.7421 7.02784L93.8717 15.4118Z' fill='#006CA9' />
        <path fillRule='evenodd' clipRule='evenodd' d='M103.97 12.9753V7.5433C103.97 6.90375 104.508 6.36676 105.148 6.36676C105.788 6.36676 106.325 6.90375 106.325 7.5433V15.8454C106.325 16.4652 105.891 17.023 105.148 17.023C104.755 17.023 104.466 16.8785 104.239 16.6095L98.8901 10.5383V15.8454C98.8901 16.486 98.3531 17.023 97.7125 17.023C97.0727 17.023 96.5361 16.486 96.5361 15.8454V7.5433C96.5361 6.92439 96.949 6.36676 97.7125 6.36676C98.0849 6.36676 98.3942 6.55256 98.6428 6.82073L103.97 12.9753Z' fill='#006CA9' />
        <path fillRule='evenodd' clipRule='evenodd' d='M112.314 8.63836V14.751H114.028C116.197 14.751 117.394 13.5534 117.394 11.6956C117.394 9.83642 116.197 8.63836 114.028 8.63836H112.314ZM109.835 7.77127C109.835 7.02784 110.393 6.53192 111.075 6.53192H114.131C117.662 6.53192 119.872 8.7418 119.872 11.6956C119.872 14.6478 117.662 16.8576 114.131 16.8576H111.075C110.393 16.8576 109.835 16.2998 109.835 15.6183V7.77127Z' fill='#006CA9' />
        <path fillRule='evenodd' clipRule='evenodd' d='M126.709 17.1056C124.829 17.1056 123.631 16.6305 122.64 15.9488C122.186 15.6396 121.979 15.2673 121.979 14.9163C121.979 14.3585 122.433 13.8841 123.053 13.8841C123.301 13.8841 123.59 13.966 123.88 14.1731C124.663 14.7101 125.49 15.0817 126.667 15.0817C127.885 15.0817 128.691 14.6892 128.691 13.9458C128.691 13.1824 127.7 12.9546 126.873 12.7684C123.176 11.9428 122.227 11.0959 122.227 9.31982C122.227 7.39941 123.858 6.28436 126.853 6.28436C127.968 6.28436 129.021 6.53188 129.992 7.00693C130.55 7.2751 130.797 7.62672 130.797 8.10133C130.797 8.59681 130.405 9.03079 129.807 9.03079C129.599 9.03079 129.352 8.98972 129.104 8.86541C128.402 8.53509 127.638 8.30822 126.873 8.30822C125.448 8.30822 124.705 8.59681 124.705 9.25788C124.705 9.83638 125.242 10.0013 126.915 10.4147C129.723 11.1166 131.169 11.7359 131.169 13.7598C131.169 15.8043 129.435 17.1056 126.709 17.1056Z' fill='#006CA9' />
        <path fillRule='evenodd' clipRule='evenodd' d='M138.067 17.1056C136.187 17.1056 134.99 16.6305 133.998 15.9488C133.543 15.6396 133.337 15.2673 133.337 14.9163C133.337 14.3585 133.792 13.8841 134.412 13.8841C134.659 13.8841 134.948 13.966 135.237 14.1731C136.022 14.7101 136.848 15.0817 138.025 15.0817C139.244 15.0817 140.049 14.6892 140.049 13.9458C140.049 13.1824 139.058 12.9546 138.232 12.7684C134.535 11.9428 133.585 11.0959 133.585 9.31982C133.585 7.39941 135.217 6.28436 138.211 6.28436C139.327 6.28436 140.379 6.53188 141.35 7.00693C141.908 7.2751 142.156 7.62672 142.156 8.10133C142.156 8.59681 141.763 9.03079 141.164 9.03079C140.957 9.03079 140.71 8.98972 140.462 8.86541C139.759 8.53509 138.996 8.30822 138.232 8.30822C136.807 8.30822 136.063 8.59681 136.063 9.25788C136.063 9.83638 136.6 10.0013 138.273 10.4147C141.082 11.1166 142.527 11.7359 142.527 13.7598C142.527 15.8043 140.793 17.1056 138.067 17.1056Z' fill='#006CA9' />
        <path fillRule='evenodd' clipRule='evenodd' d='M150.147 15.7837C150.147 16.4652 149.59 17.023 148.909 17.023C148.227 17.023 147.669 16.4652 147.669 15.7837V8.63836H144.923C144.345 8.63836 143.87 8.16374 143.87 7.58525C143.87 7.00697 144.345 6.53192 144.923 6.53192H152.895C153.473 6.53192 153.948 7.00697 153.948 7.58525C153.948 8.16374 153.473 8.63836 152.895 8.63836H150.147V15.7837Z' fill='#006CA9' />
        <path fillRule='evenodd' clipRule='evenodd' d='M160.557 14.9985C162.477 14.9985 163.696 13.5735 163.696 11.6955C163.696 9.81551 162.477 8.39058 160.557 8.39058C158.636 8.39058 157.418 9.81551 157.418 11.6955C157.418 13.5735 158.636 14.9985 160.557 14.9985ZM160.557 17.1056C157.17 17.1056 154.939 14.7929 154.939 11.6955C154.939 8.59681 157.17 6.28436 160.557 6.28436C163.944 6.28436 166.174 8.59681 166.174 11.6955C166.174 14.7929 163.944 17.1056 160.557 17.1056Z' fill='#006CA9' />
        <path fillRule='evenodd' clipRule='evenodd' d='M171.439 15.7837C171.439 16.4652 170.883 17.023 170.2 17.023C169.519 17.023 168.961 16.4652 168.961 15.7837V7.77127C168.961 7.08977 169.519 6.53192 170.2 6.53192H176.375C176.953 6.53192 177.428 7.00697 177.428 7.58525C177.428 8.16374 176.953 8.63836 176.375 8.63836H171.439V10.559H174.31C174.888 10.559 175.363 11.0338 175.363 11.6121C175.363 12.1902 174.888 12.6654 174.31 12.6654H171.439V15.7837Z' fill='#006CA9' />
        <path fillRule='evenodd' clipRule='evenodd' d='M181.29 12.7271H184.078L182.695 9.48545L181.29 12.7271ZM187.858 15.4118C187.919 15.5564 187.981 15.7635 187.981 15.9282C187.981 16.5478 187.506 17.023 186.825 17.023C186.287 17.023 185.978 16.734 185.771 16.2998L184.926 14.6058H180.465L179.618 16.2998C179.411 16.734 179.101 17.023 178.564 17.023C177.883 17.023 177.408 16.5478 177.408 15.9282C177.408 15.7635 177.47 15.5564 177.531 15.4118L181.662 7.02784C181.869 6.61428 182.158 6.36676 182.695 6.36676C183.232 6.36676 183.5 6.61428 183.727 7.02784L187.858 15.4118Z' fill='#006CA9' />
        <path fillRule='evenodd' clipRule='evenodd' d='M14.3857 27.3454C13.9172 27.3454 13.4468 27.3272 12.9878 27.2916C12.536 27.256 12.1984 26.8614 12.234 26.4096C12.2696 25.9581 12.6643 25.62 13.116 25.6556C13.5327 25.6879 13.9596 25.7053 14.3857 25.7053C16.6555 25.7053 19.0378 25.2146 21.4669 24.2483C22.6977 23.7587 23.4389 23.4635 24.2036 23.3515C24.6519 23.2856 25.0696 23.5962 25.1353 24.0438C25.2012 24.4923 24.8906 24.9096 24.4417 24.9748C23.8663 25.0587 23.1929 25.3277 22.0757 25.7718C19.4505 26.817 16.8637 27.3454 14.3857 27.3454Z' fill='#006CA9' />
        <path fillRule='evenodd' clipRule='evenodd' d='M14.3859 24.5331C12.8986 24.5331 11.4506 24.3126 10.0828 23.8797L10.0285 23.8624C9.06503 23.5558 7.86653 23.1754 5.21125 24.0895C4.78276 24.2375 4.31649 24.0089 4.16869 23.5806C4.02066 23.1523 4.24863 22.6861 4.6769 22.538C7.84611 21.4467 9.4586 21.9589 10.5258 22.2982L10.5789 22.3151C11.786 22.6981 13.0673 22.8918 14.3859 22.8918C16.2973 22.8918 18.3302 22.4688 20.4265 21.6347C23.3168 20.4841 24.7659 20.1889 27.1427 20.8214C27.5808 20.9385 27.8411 21.3874 27.7247 21.8256C27.6076 22.2639 27.1581 22.5246 26.7206 22.4078C24.8493 21.909 23.8397 22.0421 21.0336 23.1593C18.7422 24.0706 16.5057 24.5331 14.3859 24.5331Z' fill='#006CA9' />
        <path fillRule='evenodd' clipRule='evenodd' d='M14.3858 21.7202C12.6156 21.7202 10.9843 21.3472 9.39789 20.5783C8.07289 19.937 7.06129 19.1554 6.24933 18.5277C4.77608 17.3896 4.42578 17.1194 2.0156 17.5738C1.57064 17.6586 1.14127 17.3654 1.05737 16.9204C0.973254 16.4748 1.26623 16.0452 1.7112 15.962C3.21805 15.6771 4.16903 15.6569 4.99373 15.8922C5.82875 16.13 6.4571 16.6154 7.25171 17.229C8.80886 18.4321 10.9425 20.0796 14.3858 20.0796C15.9403 20.0796 17.6222 19.7233 19.3867 19.0216C21.8788 18.0293 23.5552 17.6397 25.1732 17.6773C26.8037 17.7148 28.2866 18.1826 30.3628 18.8925C30.7908 19.039 31.0208 19.5054 30.8736 19.9344C30.7278 20.3631 30.2606 20.5917 29.8317 20.445C25.8303 19.0767 24.4765 18.762 19.9929 20.5458C18.0349 21.325 16.1481 21.7202 14.3858 21.7202Z' fill='#006CA9' />
        <path fillRule='evenodd' clipRule='evenodd' d='M14.3857 18.9072C11.3423 18.9072 9.39224 17.4016 7.96863 16.302C7.4525 15.9027 6.96515 15.5267 6.45386 15.2381C6.05985 15.0152 5.91995 14.5149 6.14243 14.1202C6.36535 13.726 6.86654 13.5863 7.26033 13.8094C7.87462 14.1558 8.40678 14.5676 8.97144 15.0031C10.3454 16.0641 11.9019 17.2666 14.3857 17.2666C15.5813 17.2666 16.9149 16.9775 18.3466 16.4083C24.5759 13.9289 27.1708 14.8206 31.8796 16.4392L31.9454 16.4619C32.2305 16.5603 32.5209 16.6597 32.8193 16.7606C33.2483 16.9064 33.4773 17.3722 33.3324 17.8009C33.1865 18.2312 32.7205 18.4596 32.2909 18.3144C31.9918 18.2123 31.6997 18.1121 31.4117 18.0133L31.3467 17.9911C26.7269 16.403 24.6178 15.678 18.953 17.932C17.3271 18.5793 15.7906 18.9072 14.3857 18.9072Z' fill='#DA322A' />
        <path fillRule='evenodd' clipRule='evenodd' d='M14.5729 10.5293C14.2347 10.5293 13.9186 10.3195 13.7989 9.98263C13.6472 9.55568 13.8714 9.08678 14.2984 8.93523C14.3823 8.90558 14.5209 8.84958 14.7521 8.75734C14.8791 8.70638 15.0343 8.64379 15.2265 8.56714C19.3671 6.92016 22.8886 6.25294 26.3091 6.46949C27.1432 6.52198 27.9631 6.62499 28.8161 6.784C29.2617 6.86702 29.5554 7.29551 29.4721 7.74069C29.3889 8.18653 28.9606 8.47995 28.515 8.39715C27.7285 8.25022 26.9726 8.15512 26.2054 8.10702C23.0358 7.90716 19.7396 8.53727 15.8336 10.092C15.6427 10.1676 15.4887 10.2297 15.3625 10.2804C15.1044 10.3834 14.9619 10.4408 14.8461 10.4818C14.7563 10.5141 14.6634 10.5293 14.5729 10.5293Z' fill='#006CA9' />
        <path fillRule='evenodd' clipRule='evenodd' d='M14.4374 13.2986C13.3256 13.2986 12.5596 12.7513 11.4033 11.846L11.2996 11.7654C9.42443 10.2961 7.29845 8.63002 2.67685 8.78771C2.2231 8.80287 1.84425 8.44861 1.8291 7.99596C1.81372 7.54331 2.16798 7.16336 2.62107 7.14798C4.93307 7.0698 6.91871 7.40472 8.69109 8.17386C10.2256 8.83954 11.3347 9.70794 12.3121 10.4738L12.414 10.5537C13.1965 11.166 13.6617 11.5177 14.1214 11.62C14.596 11.7256 15.2168 11.5983 16.2669 11.1808C19.8158 9.76878 22.7935 9.15602 25.6379 9.25222C28.2864 9.34293 30.5461 10.0427 32.8192 10.8146C33.2482 10.9605 33.4772 11.4263 33.3323 11.8554C33.1864 12.2846 32.7204 12.5141 32.2908 12.3683C27.542 10.7558 23.9033 9.90824 16.873 12.7054C15.8474 13.1132 15.084 13.2986 14.4374 13.2986Z' fill='#006CA9' />
        <path fillRule='evenodd' clipRule='evenodd' d='M14.3959 16.0955C12.3028 16.0964 10.9807 15.074 9.68819 14.0756C7.80885 12.6241 5.8656 11.1227 0.973205 12.0462C0.527364 12.1304 0.0984344 11.8369 0.0145372 11.3918C-0.0700189 10.9464 0.222962 10.5177 0.667925 10.4335C6.28574 9.37384 8.72821 11.2606 10.6912 12.7765C12.5018 14.1757 13.8097 15.1858 17.307 13.7943C20.673 12.455 23.3221 11.9266 25.8964 12.081C28.2677 12.2235 30.3649 12.9443 32.7937 13.7791C33.0968 13.8836 33.4069 13.9899 33.7241 14.0976C34.153 14.2436 34.383 14.7092 34.2371 15.1381C34.0913 15.5673 33.6255 15.7972 33.1959 15.651C32.8772 15.5425 32.5653 15.436 32.2609 15.3308C27.4502 13.677 24.5466 12.6794 17.9141 15.3189C16.5225 15.8724 15.3752 16.0955 14.3959 16.0955Z' fill='#006CA9' />
        <path fillRule='evenodd' clipRule='evenodd' d='M8.06828 6.80358C7.98262 6.80358 7.89499 6.78996 7.80912 6.76141C7.26664 6.5811 5.84039 6.15458 4.32058 6.09836C3.86749 6.08167 3.51477 5.70149 3.5308 5.24862C3.54749 4.79553 3.9281 4.44084 4.38097 4.45885C6.05079 4.52034 7.56995 4.95257 8.32788 5.20448C8.75746 5.34789 8.99005 5.8124 8.84685 6.24221C8.73265 6.58615 8.41265 6.80358 8.06828 6.80358Z' fill='#006CA9' />
        <path fillRule='evenodd' clipRule='evenodd' d='M44.7441 31.1283H45.1267V0H44.7441V31.1283Z' fill='#A5A6A5' />
        <path fillRule='evenodd' clipRule='evenodd' d='M59.9976 24.5083H57.8773V26.0654H59.9976C60.6294 26.0654 60.8972 25.6629 60.8972 25.2869C60.8972 24.9109 60.6294 24.5083 59.9976 24.5083ZM57.8773 29.1933C57.8773 29.6359 57.5145 29.998 57.0717 29.998C56.6292 29.998 56.2661 29.6359 56.2661 29.1933V23.9849C56.2661 23.5419 56.6292 23.1793 57.0717 23.1793H59.7832C61.7027 23.1793 62.5079 24.1325 62.5079 25.3005C62.5079 26.4682 61.7027 27.3942 59.7832 27.3942H57.8773V29.1933Z' fill='#A5A6A5' />
        <path fillRule='evenodd' clipRule='evenodd' d='M67.8909 24.4947H65.6088V26.0654H67.8909C68.5212 26.0654 68.7907 25.6492 68.7907 25.2732C68.7907 24.8977 68.5212 24.4947 67.8909 24.4947ZM65.6088 29.1933C65.6088 29.6359 65.2466 29.998 64.8036 29.998C64.3604 29.998 63.998 29.6359 63.998 29.1933V23.9849C63.998 23.5419 64.3604 23.1793 64.8036 23.1793H67.6496C69.5691 23.1793 70.3738 24.1191 70.3738 25.2869C70.3738 26.1056 69.9851 26.7904 69.0991 27.1392L70.0518 28.8845C70.1197 28.9919 70.1594 29.1259 70.1594 29.2597C70.1594 29.6629 69.8504 29.998 69.4074 29.998C69.0589 29.998 68.8439 29.8505 68.7233 29.6223L67.4888 27.3671H65.6088V29.1933Z' fill='#A5A6A5' />
        <path fillRule='evenodd' clipRule='evenodd' d='M75.4211 28.683C76.6697 28.683 77.4619 27.7568 77.4619 26.5352C77.4619 25.3135 76.6697 24.3875 75.4211 24.3875C74.173 24.3875 73.3812 25.3135 73.3812 26.5352C73.3812 27.7568 74.173 28.683 75.4211 28.683ZM75.4211 30.0521C73.2196 30.0521 71.7705 28.5486 71.7705 26.5352C71.7705 24.5217 73.2196 23.0184 75.4211 23.0184C77.6231 23.0184 79.0726 24.5217 79.0726 26.5352C79.0726 28.5486 77.6231 30.0521 75.4211 30.0521Z' fill='#A5A6A5' />
        <path fillRule='evenodd' clipRule='evenodd' d='M88.482 29.2335C88.482 29.6493 88.1469 29.9981 87.7307 29.9981C87.3147 29.9981 86.9789 29.6493 86.9789 29.2335V26.4548L85.3548 29.5689C85.2201 29.8241 85.0192 29.9981 84.6836 29.9981C84.348 29.9981 84.1464 29.8241 84.0124 29.5689L82.3881 26.4548V29.2335C82.3881 29.6493 82.0527 29.9981 81.6358 29.9981C81.2201 29.9981 80.8843 29.6493 80.8843 29.2335V23.7565C80.8843 23.3535 81.1935 23.0721 81.6358 23.0721C81.9989 23.0721 82.2135 23.2461 82.361 23.5151L84.6836 27.9847L87.0053 23.5151C87.1537 23.2461 87.3683 23.0721 87.7307 23.0721C88.1735 23.0721 88.482 23.3535 88.482 23.7565V29.2335Z' fill='#A5A6A5' />
        <path fillRule='evenodd' clipRule='evenodd' d='M93.9459 28.683C95.1947 28.683 95.9863 27.7568 95.9863 26.5352C95.9863 25.3135 95.1947 24.3875 93.9459 24.3875C92.6971 24.3875 91.9056 25.3135 91.9056 26.5352C91.9056 27.7568 92.6971 28.683 93.9459 28.683ZM93.9459 30.0521C91.744 30.0521 90.2944 28.5486 90.2944 26.5352C90.2944 24.5217 91.744 23.0184 93.9459 23.0184C96.1473 23.0184 97.5974 24.5217 97.5974 26.5352C97.5974 28.5486 96.1473 30.0521 93.9459 30.0521Z' fill='#A5A6A5' />
        <path fillRule='evenodd' clipRule='evenodd' d='M102.322 29.1933C102.322 29.6359 101.96 29.998 101.516 29.998C101.074 29.998 100.711 29.6359 100.711 29.1933V24.5485H98.9262C98.5504 24.5485 98.2412 24.2397 98.2412 23.8641C98.2412 23.4881 98.5504 23.1793 98.9262 23.1793H104.107C104.483 23.1793 104.792 23.4881 104.792 23.8641C104.792 24.2397 104.483 24.5485 104.107 24.5485H102.322V29.1933Z' fill='#A5A6A5' />
        <path fillRule='evenodd' clipRule='evenodd' d='M107.786 28.5219H111.128C111.504 28.5219 111.813 28.8307 111.813 29.2065C111.813 29.5821 111.504 29.8907 111.128 29.8907H106.98C106.537 29.8907 106.175 29.5285 106.175 29.0857V23.9849C106.175 23.5419 106.537 23.1793 106.98 23.1793H110.967C111.343 23.1793 111.651 23.4881 111.651 23.8641C111.651 24.2397 111.343 24.5485 110.967 24.5485H107.786V25.7968H109.624C110 25.7968 110.309 26.1056 110.309 26.4814C110.309 26.8576 110 27.1662 109.624 27.1662H107.786V28.5219Z' fill='#A5A6A5' />
        <path fillRule='evenodd' clipRule='evenodd' d='M117.45 23.8777C117.45 23.4343 117.812 23.0721 118.256 23.0721C118.699 23.0721 119.06 23.4343 119.06 23.8777V29.1933C119.06 29.6359 118.699 29.9981 118.256 29.9981C117.812 29.9981 117.45 29.6359 117.45 29.1933V23.8777Z' fill='#A5A6A5' />
        <path fillRule='evenodd' clipRule='evenodd' d='M122.497 26.5352C122.497 27.6494 123.196 28.683 124.564 28.683C125.196 28.683 125.706 28.455 126.068 28.0652C126.216 27.9046 126.39 27.7302 126.699 27.7302C127.155 27.7302 127.451 28.0522 127.451 28.428C127.451 28.6289 127.343 28.8176 127.196 28.9786C126.658 29.5821 125.732 30.0521 124.564 30.0521C122.162 30.0521 120.887 28.3744 120.887 26.5352C120.887 24.6963 122.162 23.0184 124.564 23.0184C125.732 23.0184 126.658 23.4882 127.196 24.0923C127.343 24.2538 127.451 24.4413 127.451 24.6423C127.451 25.0183 127.155 25.3407 126.699 25.3407C126.39 25.3407 126.216 25.1661 126.068 25.0051C125.706 24.6157 125.196 24.3875 124.564 24.3875C123.196 24.3875 122.497 25.4209 122.497 26.5352Z' fill='#A5A6A5' />
        <path fillRule='evenodd' clipRule='evenodd' d='M130.874 28.5219H134.215C134.592 28.5219 134.9 28.8307 134.9 29.2065C134.9 29.5821 134.592 29.8907 134.215 29.8907H130.068C129.625 29.8907 129.262 29.5285 129.262 29.0857V23.9849C129.262 23.5419 129.625 23.1793 130.068 23.1793H134.054C134.43 23.1793 134.739 23.4881 134.739 23.8641C134.739 24.2397 134.43 24.5485 134.054 24.5485H130.874V25.7968H132.712C133.089 25.7968 133.397 26.1056 133.397 26.4814C133.397 26.8576 133.089 27.1662 132.712 27.1662H130.874V28.5219Z' fill='#A5A6A5' />
        <path fillRule='evenodd' clipRule='evenodd' d='M141.169 28.5219C141.545 28.5219 141.854 28.8307 141.854 29.2065C141.854 29.5821 141.545 29.8907 141.169 29.8907H137.411C136.968 29.8907 136.605 29.5285 136.605 29.0857V23.8777C136.605 23.4343 136.968 23.0721 137.411 23.0721C137.853 23.0721 138.216 23.4343 138.216 23.8777V28.5219H141.169Z' fill='#A5A6A5' />
        <path fillRule='evenodd' clipRule='evenodd' d='M145.398 27.2064H147.21L146.31 25.0989L145.398 27.2064ZM149.666 28.9513C149.707 29.0451 149.746 29.1793 149.746 29.2869C149.746 29.6897 149.438 29.9981 148.995 29.9981C148.646 29.9981 148.444 29.8107 148.31 29.5285L147.76 28.4279H144.861L144.31 29.5285C144.176 29.8107 143.975 29.9981 143.625 29.9981C143.183 29.9981 142.874 29.6897 142.874 29.2869C142.874 29.1793 142.914 29.0451 142.954 28.9513L145.639 23.5017C145.773 23.2331 145.961 23.0721 146.31 23.0721C146.659 23.0721 146.834 23.2331 146.981 23.5017L149.666 28.9513Z' fill='#A5A6A5' />
        <path fillRule='evenodd' clipRule='evenodd' d='M156.23 27.3672V23.8373C156.23 23.4209 156.579 23.0721 156.995 23.0721C157.412 23.0721 157.761 23.4209 157.761 23.8373V29.2335C157.761 29.6359 157.478 29.9981 156.995 29.9981C156.74 29.9981 156.552 29.9045 156.405 29.7299L152.928 25.7834V29.2335C152.928 29.6493 152.579 29.9981 152.163 29.9981C151.747 29.9981 151.398 29.6493 151.398 29.2335V23.8373C151.398 23.4343 151.667 23.0721 152.163 23.0721C152.404 23.0721 152.606 23.1932 152.767 23.3671L156.23 27.3672Z' fill='#A5A6A5' />
        <path fillRule='evenodd' clipRule='evenodd' d='M161.653 24.5485V28.5219H162.767C164.177 28.5219 164.955 27.7434 164.955 26.5352C164.955 25.327 164.177 24.5485 162.767 24.5485H161.653ZM160.042 23.9849C160.042 23.5017 160.405 23.1793 160.848 23.1793H162.835C165.13 23.1793 166.566 24.6157 166.566 26.5352C166.566 28.455 165.13 29.8907 162.835 29.8907H160.848C160.405 29.8907 160.042 29.5285 160.042 29.0857V23.9849Z' fill='#A5A6A5' />
      </svg>
    </div>
  )
}

export default ConsumerWrapper(Logo, ['set', 'showMenu'])
